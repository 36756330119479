import React from "react";
import styles from "./NewsSection.module.scss";
import { Grid, Button, Container } from "@material-ui/core";
import NewsBase from "../NewsBase/NewsBase";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Request from "../../../utils/Request";
function MatchCheckWrapper() {
    const match = useRouteMatch('/aktualnosci');
    return (React.createElement(NewsSection, { match: match }));
}
const pageSizeSection = 3;
const pageSizeSeparatePage = 8;
class NewsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsdata: []
        };
        this.loadNews = this.loadNews.bind(this);
        this.loadNews();
    }
    loadNews() {
        const now = new Date();
        const nowISO = now.toISOString().substring(0, 10);
        const query = '$filter=' + encodeURIComponent(`date_from <= '${nowISO}' AND (date_to >= '${nowISO}' OR date_to is null)`);
        new Request().get(`${BACKEND_SERVICE}/News?${query}`).then((result) => {
            const newsData = JSON.parse(result).data;
            this.setState({
                newsdata: newsData
            });
        });
    }
    renderNews() {
        let length;
        const objects = [];
        const singleNewsSite = !this.props.match;
        if (!singleNewsSite) {
            length = this.state.newsdata.length < pageSizeSeparatePage ? this.state.newsdata.length : pageSizeSeparatePage;
        }
        else {
            length = this.state.newsdata.length < pageSizeSection ? this.state.newsdata.length : pageSizeSection;
        }
        for (let i = 0; i < length; ++i) {
            objects.push(React.createElement(Grid, { key: this.state.newsdata[i].id, xs: 12, md: 4, item: true, container: true, justifyContent: "center", alignContent: "stretch" },
                React.createElement(NewsBase, { id: this.state.newsdata[i].id, title: this.state.newsdata[i].title, date: this.state.newsdata[i].date_from, text: this.state.newsdata[i].text, shortText: this.state.newsdata[i].short_text, image: this.state.newsdata[i].image_path })));
        }
        return objects;
    }
    render() {
        let moreButton;
        if (this.props.match === null) {
            moreButton = (React.createElement(Link, { to: "/aktualnosci", className: styles["more-button"] },
                React.createElement(Button, { color: "primary", variant: "outlined" }, "WI\u0118CEJ AKTUALNO\u015ACI")));
        }
        return (React.createElement(Container, { className: styles["main-container"] },
            React.createElement(Grid, { container: true, justifyContent: "flex-start", alignContent: "stretch", spacing: 8 }, this.renderNews()),
            React.createElement("div", { className: styles["button-container"] }, moreButton)));
    }
}
export default MatchCheckWrapper;
