import React from "react";
import styles from "./NewsBase.module.scss";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import DateFnsAdapter from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';
import { useRouteMatch } from "react-router-dom";
function NewsBase(props) {
    const match = useRouteMatch('/aktualnosci/:id');
    return (React.createElement(NewsBaseInt, { match: match, ...props }));
}
class NewsBaseInt extends React.Component {
    momentAdapter;
    cardElement;
    constructor(props) {
        super(props);
        this.momentAdapter = new DateFnsAdapter({ locale: plLocale });
        this.setExpanded = this.setExpanded.bind(this);
        const dateIO = this.momentAdapter.date(props.date);
        this.state = {
            isOverflowing: false,
            contentExpanded: false,
            dateIO: dateIO,
            dateFormatted: this.momentAdapter.format(dateIO, "d MMM, yyyy")
        };
    }
    setExpanded() {
        this.setState(prevState => ({
            contentExpanded: !prevState.contentExpanded
        }));
    }
    componentDidMount() {
        if (this.cardElement) {
            if (this.cardElement.scrollHeight > this.cardElement.clientHeight) {
                this.setState({
                    isOverflowing: true
                });
            }
        }
    }
    render() {
        const singleMode = this.props.match;
        let textSection;
        let shortTextSection;
        // if(typeof this.props.shortText === "string" && this.props.shortText.length > 0 && !singleMode) {
        //     shortTextSection = (
        //         <div className={styles['text-container']}>
        //             <Typography variant="body2" component="div" style={{marginTop: "0.5em"}}>
        //                 {this.props.shortText}
        //             </Typography>
        //         </div>
        //     );
        // }
        if (this.props.text !== null && this.props.text !== "") {
            // let dotsColor = this.state.contentExpanded ? "transparent" : "inherit";
            textSection = (React.createElement("div", { className: styles['text-container'] },
                React.createElement(Typography, { variant: "body2", component: "div", style: { marginTop: "0.5em" } }, this.props.text)));
        }
        let image;
        if (this.props.image) {
            image = (React.createElement("div", { className: styles['image-container'] },
                React.createElement("img", { src: process.env.NODE_ENV === "development" ? this.props.image : `${BACKEND_SERVICE}/${this.props.image}`, className: singleMode ? styles["image-single"] : styles["image"], alt: "test" })));
        }
        let title;
        if (this.props.title && this.props.title !== "") {
            title = React.createElement(Typography, { variant: "h4", component: "div", className: styles['title-container'] }, this.props.title);
        }
        let content = (React.createElement("div", { className: styles["card"], ref: (el) => { this.cardElement = el; } },
            React.createElement("div", { className: styles["card-container"] },
                React.createElement("div", { className: styles["card"], ref: (el) => { this.cardElement = el; } },
                    React.createElement("div", { className: styles["card-container"] },
                        React.createElement("div", { className: styles['content-container'] },
                            image,
                            title,
                            shortTextSection,
                            textSection)),
                    React.createElement("div", { className: styles["date-container"] },
                        React.createElement(Typography, { variant: "body2" }, this.state.dateFormatted))))));
        if (!singleMode) {
            content = (React.createElement("a", { href: `/aktualnosci/${this.props.id}`, style: { textDecoration: "none", color: "inherit" } }, content));
        }
        return (React.createElement(Grid, { item: true, xs: 12 }, content));
    }
}
export default NewsBase;
