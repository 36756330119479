import React from "react";

import { Typography } from "@material-ui/core";

import styles from "./Teaser.module.scss";

class Teaser extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (

            <div className={styles["main-container"]}>
                <div className={styles["content-container"]}>
                    <div className={styles["icon-container"]}>
                        {this.props.icon}
                    </div>
                    <div className={styles["title-container"]}>
                        <Typography variant="h6" style={{display: "inline-block", textAlign: "center"}}>{this.props.title}</Typography>
                    </div>
                    <div className={styles["subtitle-container"]}>
                        <Typography variant="body2" color="inherit">{this.props.subtitle}</Typography>
                    </div>
                </div>
            </div>
            
        );
    
    }

}

export default Teaser;