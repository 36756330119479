import Request from "./Request";
class ServiceHelper {
    request;
    routeCheckerUrl;
    backendServiceUrl;
    constructor() {
        this.request = new Request();
        this.routeCheckerUrl = ROUTE_CHECKER_SERVICE_URL;
        this.backendServiceUrl = BACKEND_SERVICE;
    }
    getRouteInfo(addressFrom, addressTo) {
        const url = this.routeCheckerUrl + "/routeInfo";
        const requestData = {
            addressFrom: {
                text: addressFrom
            },
            addressTo: {
                text: addressTo
            }
        };
        return new Promise(function (resolve, reject) {
            this.request.post(url, requestData).then(function (sReponse) {
                try {
                    const responseJson = JSON.parse(sReponse);
                    if (responseJson.data && responseJson.data.isAvailable) {
                        resolve(responseJson);
                    }
                    else {
                        if (responseJson && responseJson.messages) {
                            reject(responseJson.messages[0].text);
                        }
                        else {
                            reject("Coś poszło nie tak");
                        }
                    }
                }
                catch {
                    reject("Coś poszło nie tak");
                }
            }.bind(this)).catch(function (errorResponse) {
                try {
                    reject(errorResponse);
                }
                catch {
                    reject("Coś poszło nie tak");
                }
            });
        }.bind(this));
    }
    submitRequest(requestData) {
        const url = this.backendServiceUrl + "/reservation";
        return new Promise(function (resolve, reject) {
            this.request.post(url, requestData).then(function (sReponse) {
                try {
                    const responseJson = JSON.parse(sReponse);
                    resolve(responseJson);
                }
                catch {
                    reject("Coś poszło nie tak");
                }
            }.bind(this)).catch(function (error) {
                try {
                    const responseJson = JSON.parse(error);
                    reject(responseJson);
                }
                catch {
                    reject("Coś poszło nie tak");
                }
            });
        }.bind(this));
    }
}
export default ServiceHelper;
