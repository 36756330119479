import React from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Typography from '@material-ui/core/Typography';
import { isEqual } from 'lodash';

import styles from './SubmitRequestDialog.module.scss';

export default class SubmitRequestDialog extends React.Component {

    constructor(props) {
        super(props);

        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.onButtonPress = this.onButtonPress.bind(this);

    }

    shouldComponentUpdate(nextProps, nextState) {

        return !isEqual(this.props, nextProps);

    }

    isError() {

        try {

            let response = this.props.submitResponse;
            let error = false;

            let messages = response.messages;

            for(let i = 0; i < messages.length; ++i) {
                if(messages[i].type === "E") {
                    error = true;
                    break;
                }
            }

            return error;

        } catch {
            return true;
        }

    }

    onButtonPress() {

        this.props.close(!this.isError());

    }

    renderMessage(key, text, type) {
        
        let textClassName;
        let icon;

        switch(type) {
            case "S":
                textClassName = styles['success-text'];
                icon = (
                    <Grid item > 
                        <CheckOutlinedIcon
                            className={textClassName}
                            color="inherit"
                            style={{display: "block"}}
                        />
                    </Grid>
                );
                break;
            case "E":
                textClassName = styles['error-text'];
                break;
            default:
                textClassName = "";
                break;
        }

        return (
            <Grid key={key} container direction="row" alignItems="center" wrap="nowrap">
                <Grid container item style={{paddingRight: "1em"}} direction="column" wrap="wrap">
                    <Typography variant="body1" className={textClassName}>
                        {text}
                    </Typography>
                </Grid>
                {icon}
            </Grid>
        )

    }

    render() {

        let response = this.props.submitResponse;
        let renderedMessages = [];

        if(response == null) {
        } else if(typeof response === "string") {
            renderedMessages = response;
        } else {
            try {
                let messages = response.messages;

                for(let i = 0; i < messages.length; ++i) {
                    renderedMessages.push(this.renderMessage(i, messages[i].text, messages[i].type));
                }
            } catch {
                renderedMessages = "Coś poszło nie tak";
            }

        }

        return (
            <Dialog open={this.props.open} fullScreen={this.props.mediaSize === "xs"}>
                <div className={styles["dialog"]}>

                    {renderedMessages}

                    <Grid 
                        container 
                        style={{height: "100%"}} 
                        direction="row" 
                        alignItems={this.props.mediaSize === 'xs' ? 'flex-end' : 'flex-start'}>

                        <Grid item container direction="row" style={{marginTop: "2em"}} justifyContent="flex-end">
                            <Button
                                onClick={this.onButtonPress}
                                color="primary"
                                variant="contained">
                                OK
                            </Button>
                        </Grid>

                    </Grid>
                </div>
            </Dialog>
        );
    }

}