import React from 'react';

export default function Instagram() {

    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.29891 0H18.7011C21.6276 0 24 2.3724 24 5.29891V18.7011C24 21.6276 21.6276 24 18.7011 24H5.29891C2.3724 24 0 21.6276 0 18.7011V5.29891C0 2.3724 2.3724 0 5.29891 0ZM8.13358 21.1626H15.8659C18.7867 21.1626 21.1626 18.7867 21.1626 15.8659V8.13358C21.1626 5.21279 18.7867 2.83684 15.8659 2.83684H8.13358C5.21279 2.83684 2.83684 5.21279 2.83684 8.13358V15.8659C2.83684 18.7867 5.21279 21.1626 8.13358 21.1626ZM4.68041 8.13413C4.68041 6.2299 6.22936 4.68095 8.13358 4.68095V4.68041H15.8659C17.7701 4.68041 19.319 6.22936 19.319 8.13358V15.8664C19.319 17.7706 17.7701 19.3196 15.8659 19.3196H8.13358C6.22936 19.3196 4.68041 17.7706 4.68041 15.8664V8.13413ZM12 16.6382C9.44262 16.6382 7.36184 14.5574 7.36184 12C7.36184 9.44262 9.44262 7.36184 12 7.36184C14.5574 7.36184 16.6382 9.44262 16.6382 12C16.6382 14.5574 14.5574 16.6382 12 16.6382ZM12 8.90266C10.2918 8.90266 8.90266 10.2924 8.90266 12C8.90266 13.7076 10.2924 15.0974 12 15.0974C13.7076 15.0974 15.0974 13.7076 15.0974 12C15.0974 10.2924 13.7076 8.90266 12 8.90266ZM16.3154 6.13289C16.867 5.90442 17.4994 6.16635 17.7278 6.71793C17.9563 7.26951 17.6944 7.90187 17.1428 8.13034C16.5912 8.35881 15.9588 8.09688 15.7304 7.5453C15.5019 6.99372 15.7638 6.36136 16.3154 6.13289Z" fill="url(#paint0_linear_4001_6438)"/>
        <defs>
            <linearGradient id="paint0_linear_4001_6438" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F9CE34"/>
                <stop offset="0.41" stopColor="#EE2A7B"/>
                <stop offset="1" stopColor="#6228D7"/>
            </linearGradient>
        </defs>
    </svg>);

}