// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KPrpbLFZO-R8Ol0UJo6w\\+Q\\=\\={padding:4em 0 3em 0;min-height:75vh}", "",{"version":3,"sources":["webpack://./src/js/components/Views/News/NewsView.module.scss"],"names":[],"mappings":"AAAA,6BACI,mBAAA,CACA,eAAA","sourcesContent":[".main-container {\n    padding: 4em 0 3em 0;\n    min-height: 75vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "KPrpbLFZO-R8Ol0UJo6w+Q=="
};
export default ___CSS_LOADER_EXPORT___;
