import React from "react";

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './LuggageInfoDialog.module.scss';

export default function LuggageInfoDialog(props) {

    return (
        <Dialog open={props.open} fullScreen={props.mediaSize === "xs"}>
            <div className={styles["dialog"]}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Grid item style={{paddingRight: "1em"}}>
                        <Typography variant="body1">
                        <span>Pasażer ma prawo do bezpłatnego przewozu bagażu podstawowego oraz bagażu podręcznego:<br></br><br></br>
                        a) bagaż podstawowy – przewożony w luku bagażowym o wadze do 30 kg w formie dwóch toreb podróżnych miękkich lub jednej walizki o pojemności do 95 l.
                        <br></br><br></br>
                        b) bagaż podręczny – o wadze do 5 kg oraz wymiarach pozwalających na umieszczenie pod siedzeniem i nie utrudniających swobody podróży pozostałym pasażerom.
                        </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    direction="row" 
                    alignItems={props.mediaSize === 'xs' ? 'flex-end' : 'flex-start'}>

                    <Grid item container direction="row" style={{marginTop: "2em"}} justifyContent="flex-end">
                        <Button
                            onClick={props.close}>
                            ZAMKNIJ
                        </Button>
                    </Grid>

                </Grid>
            </div>
        </Dialog>
    );

}