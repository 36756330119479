/*global BASE_HREF*/

import React from "react";
import {
    BrowserRouter as Router,
    useHistory,
    useRouteMatch,
    useLocation
} from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import App from "../App.jsx";

function useQuery() {

    return new URLSearchParams(useLocation().search);

}

export default function RoutingWrapper() {

    return (
        <Router basename={BASE_HREF}>
            <AppWrapper>
            </AppWrapper>
        </Router>
    );

}

function AppWrapper() {

    let query = useQuery();
    const history = useHistory();
    let matches = [];
    let match;

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

    let mediaSize;

    if(matchesXl) {

        mediaSize = "xl";
    
    } else if(matchesLg) {

        mediaSize = "lg";
    
    } else if(matchesMd) {

        mediaSize = "md";
    
    } else if(matchesSm) {

        mediaSize = "sm";
    
    } else {

        mediaSize = "xs";
    
    }

    matches.push(useRouteMatch("/przedpodroza"));
    matches.push(useRouteMatch("/kontakt"));
    matches.push(useRouteMatch("/aktualnosci/:id"));
    matches.push(useRouteMatch("/aktualnosci"));
    matches.push(useRouteMatch("/trasyicennik"));
    matches.push(useRouteMatch());

    for(let i = 0; i < matches.length; ++i) {

        if(matches[i]) {

            match = matches[i];

            match.params.section = query.get("sekcja");

            break;
        
        }
    
    }

    function historyPush(route) {

        history.push(route);
    
    }
  
    return (
        <div>
            <App historyPush={historyPush} routeMatch={JSON.parse(JSON.stringify(match))} mediaSize={mediaSize} />
        </div>
    );

}