// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8D3eLDUnrIHdI942QJ0\\+uQ\\=\\={padding:2em;height:100%}", "",{"version":3,"sources":["webpack://./src/js/components/LuggageInfoDialog/LuggageInfoDialog.module.scss"],"names":[],"mappings":"AAEA,8BACI,WAAA,CACA,WAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.dialog {\n    padding: 2em;\n    height: 100%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "_8D3eLDUnrIHdI942QJ0+uQ=="
};
export default ___CSS_LOADER_EXPORT___;
