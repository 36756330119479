import React from "react";

import { Button, Container, Grid, Typography } from "@material-ui/core";

import styles from "./MainView.module.scss";
import RouteForm from "../../RouteForm/RouteForm";

import trasy_photo from "../../../../media/sesja/trasy_photo.webp";
import wiele_aut_photo from "../../../../media/wiele_aut_900_601.webp";
import wiele_aut2_photo from "../../../../media/sesja/3.webp";
import driver_photo from "../../../../media/sesja/9.webp";

import RoutesMap from "../../Common/RoutesMap/RoutesMap";
import Teaser from "../../Teaser/Teaser";
import Coins from "../../Common/Coins/Coins";
import DoorToDoor from "../../Common/DoorToDoor/DoorToDoor";
import Star from "../../Common/Star/Star";
import Clock from "../../Common/Clock/Clock";

import { Link } from "react-router-dom";


class MainView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <div className={styles["main-container"]}>

                <div className={styles["intro-photo-container"]}>

                    <Container style={{paddingBottom: "2rem"}}>
                        <div className={styles["intro-text-container"]} style={{display: "flex", flexDirection: "column"}}>

                            <div>

                                <Typography variant="h3">
                                    Transport Osobowy
                                </Typography>

                            </div>

                            <div className={styles["subtitle-container"]}>
                                <Typography variant="body1">
                                    <br/>
                                    Oferujemy transport osobowy na terenie Polski, Niemiec i&nbsp;Holandii w&nbsp;bezkonkurencyjnych cenach
                                </Typography>
                            </div>

                            <div className={styles["route-form"]}>

                                <Grid item>
                                    <Typography variant="h5">
                                        Wybierz trasę
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>

                                    <RouteForm 
                                        formControlsfullWidth={true}
                                        setNewRouteFormData={this.props.setNewRouteFormData}
                                        historyPush={this.props.historyPush}
                                        routeFormData={this.props.routeFormData}
                                        mediaSize={this.props.mediaSize}
                                        activeStep="basic_form"
                                        activeStepIndex={-1}
                                    />

                                </Grid>

                            </div>

                        </div>
                    </Container>

                </div>

                <Container className={styles["main-content-container"]} style={{padding: "5rem 2rem"}}>

                    <Grid container spacing={8}>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                Trasy
                            </Typography>
                            <Typography variant="body1">
                                <br/>
                                Nasza firma obsługuje województwa opolskie, śląskie, dolnośląskie, a&nbsp;także wybrane obszary województw lubuskiego, łódzkiego oraz małopolskiego.
                                <br/>
                                <br/>
                            </Typography>
                            <Link to="/trasyicennik">
                                <Button variant="contained" color="primary">
                                    Sprawdź adresy
                                </Button>
                            </Link>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <img src={trasy_photo} style={{display: "block", height: "100%", width: "100%", zIndex: 1000}}/>
                        </Grid>

                    </Grid>

                    <Grid container spacing={8} style={{paddingTop: "2rem"}}>

                        <Grid item xs={12} md={6}>
                            <img src={wiele_aut2_photo} style={{display: "block", height: "100%", width: "100%", zIndex: 1000}}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                Cennik
                            </Typography>
                            <Typography variant="body1">
                                <br/>
                                Ceny w&nbsp;naszej firmie są uzależnione od miejscowości w&nbsp;Polsce. Przewozy do Niemiec zaczynają się od 390 zł, a&nbsp;do Holandii od 430 zł.
                                <br/>
                                <br/>
                            </Typography>
                            <Link to="/trasyicennik?sekcja=cennik">
                                <Button variant="contained" color="primary">
                                    Sprawdź cennik
                                </Button>
                            </Link>
                        </Grid>

                    </Grid>

                    <Grid container spacing={8}>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                Szybki i&nbsp;bezpieczny transport do Niemiec i&nbsp;Holandii
                            </Typography>
                            <Typography variant="body1">
                                <br/>
                                Firma Lisoń Transport Osobowy działa od 1997 roku, specjalizując się w&nbsp;międzynarodowym, licencjonowanym przewozie osób na trasie <span style={{whiteSpace: "nowrap"}}>Polska - Niemcy - Holandia.</span> Odbieramy pasażerów bezpośrednio z&nbsp;miejsca zamieszkania i&nbsp;dowozimy ich pod wskazany adres. Państwa bezpieczeństwo jest naszym priorytetem, a&nbsp;wieloletnie doświadczenie gwarantuje najwyższą jakość usług.
                                <br/>
                                <br/>
                            </Typography>
                            <Button variant="contained" color="primary">
                                Zobacz więcej
                            </Button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <img src={wiele_aut_photo} style={{display: "block", height: "100%", width: "100%", zIndex: 1000}}/>
                        </Grid>

                    </Grid>

                </Container>

                <div style={{backgroundColor: "#0057DF", padding: "5rem 0"}}>

                    <Container>

                        <Grid container spacing={8}>

                            <Grid item xs={12} md={6}>

                                <RoutesMap/>

                            </Grid>

                            <Grid item xs={12} md={6}>

                                <Grid container>

                                    <Grid item xs={6}>
                                        <Teaser
                                            icon={<Coins/>}
                                            title="Tanie bilety"
                                            subtitle="już od 380 zł"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Teaser
                                            icon={<DoorToDoor/>}
                                            title="Door to Door"
                                            subtitle="Codziennie"
                                        />  
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Teaser
                                            icon={<Star/>}
                                            title="Doświadczenie"
                                            subtitle="Działamy od 1997 roku"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Teaser
                                            icon={<Clock/>}
                                            title="Punktualność"
                                            subtitle="Dowozimy na czas"
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Container>

                </div>

                <Container style={{padding: "5rem 2rem"}}>

                    <Grid container spacing={8}>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">
                                Najczęściej zadawane pytania
                            </Typography>
                            <Typography variant="body1">
                                <br/>
                                Jesteśmy do Twojej dyspozycji i&nbsp;chętnie odpowiemy na wszelkie pytania dotyczące naszych usług.
                                <br/>
                                <br/>
                            </Typography>
                            <Button variant="contained" color="primary">
                                Zobacz więcej
                            </Button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <img src={driver_photo} style={{display: "block", height: "100%", width: "100%", zIndex: 1000}}/>
                        </Grid>

                    </Grid>

                </Container>

            </div>
        );
    
    }

}

export default MainView;