import React from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import plLocale from 'date-fns/locale/pl'
import DateFnsIO from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";
import { isEqual } from 'lodash';

import styles from './User.module.scss';

class UserForm extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {

        return !isEqual(this.props, nextProps);

    }

    render() {

        let dateOfBirthControl;
        
        if(this.props.dateOfBirth !== undefined) {
            dateOfBirthControl = (
                <Grid item className={styles['field-container']}>
                    <MuiPickersUtilsProvider utils={DateFnsIO} locale={plLocale}>
                        <KeyboardDatePicker
                            label={`Data urodzenia`}
                            format="dd.MM.yyyy"
                            onChange={this.props.inputChange.bind(this.props.this, `${this.props.pathPrefix}/${this.props.index}/dateOfBirth`)}
                            value={this.props.dateOfBirth}
                            placeholder="Wybierz datę"
                            okLabel="Ok"
                            clearLabel="Wyczyść"
                            cancelLabel="Anuluj"
                            error={this.props.dateOfBirthStatus.error}
                            helperText={this.props.dateOfBirthStatus.infoText}
                            disableFuture={true}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            );
        }

        return (
            <Grid container className={styles['main-container']}>
                <Grid item className={styles['field-container']}>
                    <TextField
                        label="Imię"
                        value={this.props.firstName}
                        onChange={this.props.inputChange.bind(this.props.this, `${this.props.pathPrefix}/${this.props.index}/firstName`)}
                        error={this.props.firstNameStatus.error}
                        helperText={this.props.firstNameStatus.infoText}
                    />
                </Grid>
                <Grid item className={styles['field-container']}>
                    <TextField
                        label="Nazwisko"
                        value={this.props.lastName}
                        onChange={this.props.inputChange.bind(this.props.this, `${this.props.pathPrefix}/${this.props.index}/lastName`)}
                        error={this.props.lastNameStatus.error}
                        helperText={this.props.lastNameStatus.infoText}
                    />
                </Grid>
                {dateOfBirthControl}
            </Grid>
        );

    }

}

export default UserForm;