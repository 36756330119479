import React from "react";

import { Toolbar, Container, Hidden } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { MenuOutlined } from "@material-ui/icons";
import { PhoneOutlined } from "@material-ui/icons";
import { MailOutlined } from "@material-ui/icons";

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import styles from "./Header.module.scss";
import { CustomAppBarContainer } from "./CustomAppBar/CustomAppBar.jsx";

import { Link } from "react-router-dom";

import MenuPC from "./MenuPC/MenuPC.jsx";
import Facebook from "../Common/Facebook/Facebook";
import Instagram from "../Common/Instagram/Instagram";
import Whatsapp from "../Common/Whatsapp/Whatsapp";
import Logo from "../Common/Logo/Logo";

function HideOnScroll(props) {

    const { children } = props;
    const trigger = useScrollTrigger({
        threshold: 50
    });
  
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );

}


class Header extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <HideOnScroll {...this.props}>
                <CustomAppBarContainer>
                    <Container>
                        <Toolbar variant="regular" disableGutters={true}>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <div className={styles["header-container"]}>
                                    
                                    <Hidden mdUp={true}>
                                        <IconButton color="primary" aria-label="menu" onClick={this.props.openMenu}>
                                            <MenuOutlined/>
                                        </IconButton>
                                    </Hidden>
                                    
                                    <div className={styles["logo-container"]}>
                                        <Link to="/">
                                            <Hidden smDown={true}>
                                                <Logo
                                                    color="#002e70"
                                                    height="45"
                                                />
                                            </Hidden>
                                            <Hidden mdUp={true}>
                                                <Logo
                                                    color="#002e70"
                                                    height="30"
                                                />
                                            </Hidden>
                                        </Link>
                                    </div>

                                    <Hidden smDown={true}>
                                        <MenuPC/>
                                    </Hidden>

                                    <div style={{display: "flex", flexDirection: "row", gap: "0rem", grow: 1, minWidth: "100px", justifyContent: "flex-end"}}>

                                        <Hidden smUp={true}>

                                            <IconButton color="primary" aria-label="menu" href="tel:+48774311370">
                                                <PhoneOutlined/>
                                            </IconButton>

                                        </Hidden>

                                        <Hidden smDown={true}>

                                            <IconButton color="primary" aria-label="menu" href="mailto:rezerwacje@translison.pl">
                                                <MailOutlined/>
                                            </IconButton>

                                        </Hidden>

                                        <IconButton color="primary" aria-label="menu" target="_blank" href="https://www.facebook.com/LisonTransport/">
                                            <Facebook/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" target="_blank" href="https://www.instagram.com/translison.pl/">
                                            <Instagram/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" target="_blank" href="https://wa.me/48692400449">
                                            <Whatsapp/>
                                        </IconButton>

                                    </div>

                                </div>
                            </div>
                        </Toolbar>
                    </Container>
                </CustomAppBarContainer>
            </HideOnScroll>
        );

    }

}

export default Header;