import React from "react";

export default function Coins() {

    return (
        <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5016 0C23.2323 0 17.7533 3.07241 17.7533 7.14377V15.7146C17.7533 16.5037 18.3861 17.1417 19.1688 17.1417C19.9516 17.1417 20.5844 16.5037 20.5844 15.7146V11.7356C22.8826 13.3138 26.4132 14.2875 30.5016 14.2875C34.5901 14.2875 38.1207 13.3138 40.4189 11.7356V15.7146C40.4189 17.7377 36.1722 19.9958 30.5016 19.9958C30.0603 19.9958 29.6273 19.979 29.1777 19.9539C28.4116 19.9119 27.7538 20.4995 27.6955 21.2718C27.6372 22.0609 28.2201 22.7492 28.9945 22.808C29.5108 22.8416 30.002 22.8584 30.5016 22.8584C34.5984 22.8584 38.1207 21.8846 40.4189 20.3064V24.2855C40.4189 26.3085 36.1722 28.5667 30.5016 28.5667C30.0853 28.5667 29.7023 28.5667 29.3442 28.5415C28.5615 28.4827 27.887 29.0787 27.8287 29.8678C27.7788 30.6569 28.37 31.3369 29.1444 31.3872C29.6023 31.4208 30.0603 31.4208 30.5016 31.4208C37.771 31.4208 43.25 28.3484 43.25 24.2855V7.14377C43.25 3.07241 37.771 0 30.5016 0ZM30.5016 11.425C24.8311 11.425 20.5844 9.15846 20.5844 7.14377C20.5844 5.12907 24.8311 2.86254 30.5016 2.86254C36.1722 2.86254 40.4189 5.12068 40.4189 7.14377C40.4189 9.16686 36.1722 11.425 30.5016 11.425Z" fill="white"/>
            <path d="M13.4984 17.1416C6.22904 17.1416 0.75 20.214 0.75 24.2854V32.8562C0.75 36.9276 6.22904 40 13.4984 40C20.7677 40 26.2467 36.9276 26.2467 32.8562V24.2854C26.2467 20.214 20.7677 17.1416 13.4984 17.1416ZM13.4984 19.9958C19.1772 19.9958 23.4156 22.2539 23.4156 24.277C23.4156 26.3001 19.1772 28.5582 13.4984 28.5582C7.81947 28.5582 3.58112 26.3001 3.58112 24.277C3.58112 22.2539 7.81947 19.9958 13.4984 19.9958ZM13.4984 37.1375C7.81947 37.1375 3.58112 34.8709 3.58112 32.8562V28.8772C5.87932 30.4554 9.40989 31.4292 13.4984 31.4292C17.5868 31.4292 21.1174 30.4554 23.4156 28.8772V32.8562C23.4156 34.8793 19.1772 37.1375 13.4984 37.1375Z" fill="white"/>
        </svg>
    );

}