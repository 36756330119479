import React from 'react';
import { TableContainer, TableCell, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import Formatter from '../../../utils/Formatter';
function PriceTable(props) {
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { "aria-label": "simple table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Sk\u0105d"),
                    React.createElement(TableCell, { align: "left" }, "Dok\u0105d"),
                    React.createElement(TableCell, { align: "right" }, "W jedn\u0105 stron\u0119"),
                    React.createElement(TableCell, { align: "right" }, "W dwie strony"))),
            React.createElement(TableBody, null, props.basicPrices.map((row) => (React.createElement(TableRow, { key: `${row.from}${row.to}` },
                React.createElement(TableCell, { component: "th", scope: "row", dangerouslySetInnerHTML: { __html: row.from } }),
                React.createElement(TableCell, { align: "left", dangerouslySetInnerHTML: { __html: row.to } }),
                React.createElement(TableCell, { align: "right" }, row.ONE_D.map((price) => (React.createElement("p", { key: price.currency, style: { whiteSpace: "nowrap" } }, `${Formatter.formatPrice(price.amount)} ${price.currency}`)))),
                React.createElement(TableCell, { align: "right" }, row.TWO_D.map((price) => (React.createElement("p", { key: price.currency, style: { whiteSpace: "nowrap" } }, `${Formatter.formatPrice(price.amount)} ${price.currency}*`)))))))))));
}
export default PriceTable;
