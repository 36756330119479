import React from "react";

import { Grid, StepConnector } from "@material-ui/core";
import RouteForm from "../../RouteForm/RouteForm";
import { Stepper, Step, StepLabel } from '@material-ui/core';

import styles from "./RouteFormDetailsView.module.scss";

class RouteFormDetailsView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            activeStep: "address",
            activeStepIndex: 0,
            steps: [
                {
                    key: "address",
                    label: "Adresy",
                    ref: React.createRef()
                },
                {
                    key: "contact_data",
                    label: "Dane Kontaktowe",
                    ref: React.createRef()
                },
                {
                    key: "tickets",
                    label: "Bilet",
                    ref: React.createRef()
                },
                {
                    key: "payment",
                    label: "Płatność",
                    ref: React.createRef()
                },
                {
                    key: "additional_info",
                    label: "Dodatkowe informacje",
                    ref: React.createRef()
                }
            ]
        };

        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);

        this.stepperContainerRef = React.createRef();

    }

    componentDidUpdate() {

        if(this.props.mediaSize === "xs") {

            try {

                //scroll stepper to view next steps on mobile device
                const stepperContainer = this.stepperContainerRef.current;
                const stepElement = this.state.steps[this.state.activeStepIndex].ref.current;

                var parentPos = stepperContainer.getBoundingClientRect();
                var childPos = stepElement.getBoundingClientRect();
                var relativePos = {};

                relativePos.left = childPos.left - parentPos.left;

                stepperContainer.scrollLeft = stepperContainer.scrollLeft + relativePos.left;

            } catch {
                //do nothing
            }
        
        }

    }

    nextStep() {

        let nextStep;
        let nextStepIndex;

        for(let i = 0; i < this.state.steps.length; ++i) {

            if(this.state.steps[i].key === this.state.activeStep) {

                nextStepIndex = i + 1;
                nextStep = this.state.steps[nextStepIndex].key;
                break;
            
            }
        
        }

        this.setState({
            activeStep: nextStep,
            activeStepIndex: nextStepIndex
        });

        //scroll to top
        this.scrollToTop(this.props.mediaSize !== 'xs');

    }

    scrollToTop(withoutAnimation) {

        setTimeout(function() {

            document.documentElement.scrollIntoView({
                behavior: withoutAnimation ? "auto" : "smooth"
            });
        
        }.bind(this), 0);  
    
    }

    previousStep() {

        let previousStep;
        let previousStepIndex;

        for(let i = 0; i < this.state.steps.length; ++i) {

            if(this.state.steps[i].key === this.state.activeStep) {

                previousStepIndex = i - 1;
                previousStep = this.state.steps[previousStepIndex].key;
                break;
            
            }
        
        }

        this.setState({
            activeStep: previousStep,
            activeStepIndex: previousStepIndex
        });

        //scroll to top
        this.scrollToTop(this.props.mediaSize !== 'xs');

    }

    render() {

        return (
            <div className={styles["main-container"]}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">

                    <Grid item xs={12} md={8} lg={7}>

                        <div>
                            <Stepper 
                                activeStep={this.state.activeStepIndex} 
                                styles={{padding: "0"}} 
                                alternativeLabel={!!(this.props.mediaSize === "sm" || this.props.mediaSize === "xs")}
                                connector={<StepConnector/>}
                                style={{width: "100%"}}
                                className={styles["stepper-container"]}
                                ref={this.stepperContainerRef}>

                                {this.state.steps.map((step) => {

                                    return (
                                        <Step key={step.key} className={styles['step']} ref={step.ref}>
                                            <StepLabel>{step.label}</StepLabel>
                                        </Step>
                                    );
                                
                                })
                                }

                            </Stepper>
                        </div>

                    </Grid>

                    <Grid item xs={12} md={8} lg={6}>
                        <RouteForm
                            formControlsfullWidth={true}
                            className={styles["route-form"]}
                            details={true}
                            routeFormData={this.props.routeFormData}
                            setNewRouteFormData={this.props.setNewRouteFormData}
                            historyPush={this.props.historyPush}
                            mediaSize={this.props.mediaSize}
                            steps={this.state.steps}
                            activeStep={this.state.activeStep}
                            activeStepIndex={this.state.activeStepIndex}
                            nextStep={this.nextStep}
                            previousStep={this.previousStep}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    
    }

}

export default RouteFormDetailsView;