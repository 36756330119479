import React from "react";

import styles from "./MenuButton.module.scss";

class MenuButton extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };
    
    }

    render() {

        if(typeof this.props.variant === "string" && this.props.variant === "contained") {

            return (
                <button className={styles['contained-button']}>
                    {this.props.children}
                </button>
            );

        } else {
        
            return (
                <button style={{padding: '1rem', backgroundColor: 'transparent', textDecoration: 'none', cursor: 'pointer', border: 'none'}}>
                    {this.props.children}
                </button>
            );

        }

    }

}

export default MenuButton;