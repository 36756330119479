import React from "react";
import ReactDOM from "react-dom";
import MainWrapper from "./js/components/App/RoutingWrapper/RoutingWrapper.jsx";
async function enableMocking() {
    if (process.env.NODE_ENV !== 'development') {
        console.log("Mock server will not start");
        return;
    }
    const { worker } = await import('./tests/mocks/service/backend');
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
}
enableMocking().then(() => {
    const domContainer = document.querySelector('#root');
    ReactDOM.render(React.createElement(MainWrapper, null), domContainer);
});
