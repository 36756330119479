// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jxghEz8lQoKA22701nq3fg\\=\\={padding:0}.Q09DBaE1tVRpM4fRsPdP1Q\\=\\={padding:.5em .5em .5em 0}", "",{"version":3,"sources":["webpack://./src/js/components/RouteForm/User/User.module.scss"],"names":[],"mappings":"AAAA,4BACI,SAAA,CAGJ,4BACI,wBAAA","sourcesContent":[".main-container {\n    padding: 0;\n}\n\n.field-container {\n    padding: 0.5em 0.5em 0.5em 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "jxghEz8lQoKA22701nq3fg==",
	"field-container": "Q09DBaE1tVRpM4fRsPdP1Q=="
};
export default ___CSS_LOADER_EXPORT___;
