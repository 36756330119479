import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import { isEqual } from 'lodash';
import styles from './RouteInfoDialog.module.scss';
import Formatter from "../../../utils/Formatter";
class RouteInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.goToForm = this.goToForm.bind(this);
    }
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props, nextProps);
    }
    goToForm() {
        this.props.historyPush("/formularz");
        this.props.close();
    }
    render() {
        const pricesSet = this.props.routeInfoData && this.props.routeInfoData.data ? this.props.routeInfoData.data.pricesSet : [];
        let oneDirectionPrices = [];
        let twoDirectionsPrices = [];
        for (let i = 0; i < pricesSet.length; ++i) {
            switch (pricesSet[i].type) {
                case "ONE_D" /* TicketTypeEnum.OneDirection */:
                    oneDirectionPrices = pricesSet[i].prices;
                    break;
                case "TWO_D" /* TicketTypeEnum.TwoDirections */:
                    twoDirectionsPrices = pricesSet[i].prices;
                    break;
            }
        }
        return (React.createElement(Dialog, { open: this.props.open, fullScreen: this.props.mediaSize === "xs" },
            React.createElement("div", { className: styles["dialog"] },
                React.createElement(Grid, { container: true, direction: "row", alignItems: "center", wrap: "nowrap", spacing: 4 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body1", className: styles['success-text'] }, "Realizujemy po\u0142\u0105czenia na wybranej trasie")),
                    React.createElement(Grid, { item: true },
                        React.createElement(CheckOutlinedIcon, { className: styles['success-text'], color: "inherit", style: { display: "block" } }))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "body2", className: styles['additional-instruction'] }, "Kliknij w jedn\u0105 z opcji trasy")),
                React.createElement(Grid, { container: true, direction: "row", alignItems: "center", style: { marginTop: "1.5em" }, justifyContent: "space-around" },
                    React.createElement(Grid, { item: true },
                        React.createElement(ButtonBase, { onClick: this.props.inputChange.bind(this.props.this, "ticketType", "ONE_D" /* TicketTypeEnum.OneDirection */) },
                            React.createElement(Paper, { className: styles["prices-container"] +
                                    (this.props.routeFormData.ticketType === "ONE_D" /* TicketTypeEnum.OneDirection */ ? " " + styles['active-ticket'] : "") },
                                React.createElement(Grid, { container: true, direction: "column" }, oneDirectionPrices.map((price) => {
                                    return (React.createElement(Grid, { item: true, key: price.currency },
                                        React.createElement(Typography, { style: { padding: "0.5em" } },
                                            Formatter.formatPrice(price.amount),
                                            " ",
                                            price.currency)));
                                })),
                                React.createElement(Typography, { variant: "body2", className: styles['price-info-text'] }, "W jedn\u0105 stron\u0119")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(ButtonBase, { onClick: this.props.inputChange.bind(this.props.this, "ticketType", "TWO_D" /* TicketTypeEnum.TwoDirections */) },
                            React.createElement(Paper, { className: styles["prices-container"] +
                                    (this.props.routeFormData.ticketType === "TWO_D" /* TicketTypeEnum.TwoDirections */ ? " " + styles['active-ticket'] : "") },
                                React.createElement(Grid, { container: true, direction: "column" }, twoDirectionsPrices.map((price) => {
                                    return (React.createElement(Grid, { item: true, key: price.currency },
                                        React.createElement(Typography, { style: { padding: "0.5em" } },
                                            Formatter.formatPrice(price.amount),
                                            " ",
                                            price.currency,
                                            "*")));
                                })),
                                React.createElement(Typography, { variant: "body2", className: styles['price-info-text'] }, "W dwie strony"))))),
                React.createElement(Grid, { container: true, justifyContent: "flex-end" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2", className: styles['extra-info'] }, "*wymagana przedp\u0142ata"))),
                React.createElement(Grid, { container: true, direction: "row", alignItems: this.props.mediaSize === 'xs' ? 'flex-end' : 'flex-start', style: { flexGrow: 1 } },
                    React.createElement(Grid, { item: true, container: true, direction: "row", style: { marginTop: "2em" }, justifyContent: "flex-end" },
                        React.createElement(Button, { onClick: this.goToForm, variant: "outlined", color: "primary", style: { marginRight: "1em" } }, "ZAREZERWUJ"),
                        React.createElement(Button, { onClick: this.props.close }, "ZAMKNIJ"))))));
    }
}
export default RouteInfoDialog;
