// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oTz96s7h3HTuZ0s\\+hVJOrg\\=\\={padding:2em}.vKk8BYhsC2HlP46xR1g3Nw\\=\\={color:green}._1vcgGn9AIVNIIDr8OHw1mA\\=\\={color:red}.j2p1XpaqgrGs4LTXxKqeQA\\=\\={padding:1em}.dNmONvwPByIV9cCCtrDWQA\\=\\={color:#616161}.knGDXbpf2ruaxh7xH5saXA\\=\\={color:#616161;padding-top:1em}", "",{"version":3,"sources":["webpack://./src/js/components/RouteForm/SubmitRequestDialog/SubmitRequestDialog.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,WAAA,CAGJ,4BACI,WCYY,CDThB,6BACI,SCSU,CDNd,4BACI,WAAA,CAGJ,4BACI,aCdgB,CDiBpB,4BACI,aClBgB,CDmBhB,eAAA","sourcesContent":["@import \"../../../../css/variables.module\";\n\n.dialog {\n    padding: 2em;\n}\n\n.success-text {\n    color: $success_color;\n}\n\n.error-text {\n    color: $error_color;\n}\n\n.prices-container {\n    padding: 1em;\n}\n\n.price-info-text {\n    color: $subtle-text-color;\n}\n\n.extra-info {\n    color: $subtle-text-color;\n    padding-top: 1em;\n}","$primary-color: #0057DF;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "oTz96s7h3HTuZ0s+hVJOrg==",
	"success-text": "vKk8BYhsC2HlP46xR1g3Nw==",
	"error-text": "_1vcgGn9AIVNIIDr8OHw1mA==",
	"prices-container": "j2p1XpaqgrGs4LTXxKqeQA==",
	"price-info-text": "dNmONvwPByIV9cCCtrDWQA==",
	"extra-info": "knGDXbpf2ruaxh7xH5saXA=="
};
export default ___CSS_LOADER_EXPORT___;
