import React from "react";

// import {  } from "@material-ui/core";

import styles from "./MenuPC.module.scss";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";


class MenuPC extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <div className={styles["main-container"]}>
                <Link to="/trasyicennik" style={{textDecoration: "none"}} className={styles["button"]}>
                    <MenuButton>
                        Trasy / Cennik
                    </MenuButton>
                </Link>
                <Link to="/onas" style={{textDecoration: "none"}} className={styles["button"]}>
                    <MenuButton>
                        O nas
                    </MenuButton>
                </Link>
                <Link to="/przedpodroza" style={{textDecoration: "none"}} className={styles["button"]}>
                    <MenuButton>
                        Przed podróżą
                    </MenuButton>
                </Link>
                <Link to="/aktualnosci" style={{textDecoration: "none"}} className={styles["button"]}>
                    <MenuButton>
                        Aktualności
                    </MenuButton>
                </Link>
                <Link to="/kontakt" style={{textDecoration: "none"}} className={styles["button"]}>
                    <MenuButton variant="contained">
                        Kontakt
                    </MenuButton>
                </Link>
            </div>
        );
    
    }

}

export default MenuPC;