import React from "react";

import { Grid } from "@material-ui/core";
import NewsSection from "../../News/NewsSection/NewsSection";

import styles from "./NewsView.module.scss";

class NewsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <div className={styles["main-container"]}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">

                    <NewsSection/>

                </Grid>
            </div>
        )
    };
}

export default NewsView;