import React from "react";

export default function Star() {

    return (
        <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.0002 2L29.4986 15.1651L44.0307 17.2892L33.5155 27.531L35.9971 42L23.0002 35.1651L10.0034 42L12.485 27.531L1.96973 17.2892L16.5018 15.1651L23.0002 2Z" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

}
