// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t8luj\\+1Ap2AlLSKWQJjNYg\\=\\={padding:2em 1em 3em 1em}.rBwf6ESwd38Y\\+k-KH69Yhw\\=\\={margin-top:4rem;min-height:75vh;padding:2em 0 2em 0}.nxI5neC5zxfIv4w6d7V4rg\\=\\={padding:0 0em}.D17RhIApwi1cNwmbrUG19Q\\=\\={min-width:6em}@media(max-width: 600px){.nxI5neC5zxfIv4w6d7V4rg\\=\\={overflow:scroll;-ms-overflow-style:none;scrollbar-width:none}.nxI5neC5zxfIv4w6d7V4rg\\=\\=::-webkit-scrollbar{display:none}}", "",{"version":3,"sources":["webpack://./src/js/components/Views/RouteFormDetails/RouteFormDetailsView.module.scss"],"names":[],"mappings":"AAAA,6BACI,uBAAA,CAGJ,6BACI,eAAA,CACA,eAAA,CACA,mBAAA,CAGJ,4BACI,aAAA,CAGJ,4BACI,aAAA,CAGJ,yBAEI,4BACI,eAAA,CAGA,uBAAA,CACA,oBAAA,CAIJ,+CACI,YAAA,CAAA","sourcesContent":[".route-form {\n    padding: 2em 1em 3em 1em;\n}\n\n.main-container {\n    margin-top: 4rem;\n    min-height: 75vh;\n    padding: 2em 0 2em 0;\n}\n\n.stepper-container {\n    padding: 0 0em;\n}\n\n.step {\n    min-width: 6em;\n}\n\n@media (max-width: 600px) {\n\n    .stepper-container {\n        overflow: scroll;\n\n        /* Hide scrollbar for IE, Edge and Firefox */\n        -ms-overflow-style: none;  /* IE and Edge */\n        scrollbar-width: none;  /* Firefox */\n    }\n\n    /* Hide scrollbar for Chrome, Safari and Opera */\n    .stepper-container::-webkit-scrollbar {\n        display: none;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"route-form": "t8luj+1Ap2AlLSKWQJjNYg==",
	"main-container": "rBwf6ESwd38Y+k-KH69Yhw==",
	"stepper-container": "nxI5neC5zxfIv4w6d7V4rg==",
	"step": "D17RhIApwi1cNwmbrUG19Q=="
};
export default ___CSS_LOADER_EXPORT___;
