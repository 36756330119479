// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g-2YMmq68x9XiGUjDp1wLw\\=\\={margin:0}.FQOt26luUL9xOQ4Y5K06WQ\\=\\={margin-top:1.5em;display:flex;flex-direction:row;justify-content:end}@media only screen and (max-width: 480px){.FQOt26luUL9xOQ4Y5K06WQ\\=\\={justify-content:center}}.zKmkBAaZ2CjLZLyzxMZXpA\\=\\={z-index:100}._1aWurLnFSfPAB6ikH1VtSw\\=\\={color:red !important}@media only screen and (min-width: 1200px){.xxbO\\+jamHL0B9ylrX4FFoQ\\=\\={display:flex;flex-direction:row;gap:2rem;flex-basis:0}}.JjAfI6NA4tim-VBfqT737w\\=\\={padding-top:2.6rem}@media only screen and (max-width: 480px){.JjAfI6NA4tim-VBfqT737w\\=\\={width:100%;display:flex;flex-direction:row;justify-content:end}}", "",{"version":3,"sources":["webpack://./src/js/components/RouteForm/RouteForm.module.scss"],"names":[],"mappings":"AAEA,4BACI,QAAA,CAGJ,4BACI,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,mBAAA,CAGJ,0CACI,4BACI,sBAAA,CAAA,CAIR,4BACI,WAAA,CAGJ,6BACI,oBAAA,CAGJ,2CACI,6BACI,YAAA,CACA,kBAAA,CACA,QAAA,CACA,YAAA,CAAA,CAIR,4BACI,kBAAA,CAGJ,0CACI,4BACI,UAAA,CACA,YAAA,CACA,kBAAA,CACA,mBAAA,CAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.form {\n    margin: 0;\n}\n\n.btn-container {\n    margin-top: 1.5em;\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n}\n\n@media only screen and (max-width: 480px) {\n    .btn-container {\n        justify-content: center;\n    }\n}\n\n.btn-busy-indicator {\n    z-index: 100;\n}\n\n.error {\n    color: $error_color !important;\n}\n\n@media only screen and (min-width: 1200px) {\n    .first-step-container {\n        display: flex;\n        flex-direction: row;\n        gap: 2rem;\n        flex-basis: 0;\n    }\n}\n\n.check-route-button-container {\n    padding-top: 2.6rem;\n}\n\n@media only screen and (max-width: 480px) {\n    .check-route-button-container {\n        width: 100%;\n        display: flex;\n        flex-direction: row;\n        justify-content: end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "g-2YMmq68x9XiGUjDp1wLw==",
	"btn-container": "FQOt26luUL9xOQ4Y5K06WQ==",
	"btn-busy-indicator": "zKmkBAaZ2CjLZLyzxMZXpA==",
	"error": "_1aWurLnFSfPAB6ikH1VtSw==",
	"first-step-container": "xxbO+jamHL0B9ylrX4FFoQ==",
	"check-route-button-container": "JjAfI6NA4tim-VBfqT737w=="
};
export default ___CSS_LOADER_EXPORT___;
