import React from "react";

import styles from "./Footer.module.scss";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

import { Grid, Container, Typography, Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import Holland from "../Common/Holland/Holland";
import GermanyMap from "../Common/Germany/Germany";
import Logo from "../Common/Logo/Logo";

import { useRouteMatch } from "react-router-dom";


export default function Footer() {

    let oMoneyIcon = <AttachMoneyOutlinedIcon
        color="inherit"
    />;

    function FooterLink(propsInt) {

        return (
            <Typography style={{lineHeight: "0.75rem"}} component={'span'}>
                <Link to={propsInt.to} style={{textDecoration: "none", color: "white", fontSize: "0.75rem"}}>
                    <p>{propsInt.children}</p>
                </Link>
            </Typography>
        );

    }

    function FooterText(propsInt) {

        return (
            <Typography component='span' style={{textDecoration: "none", color: "white", fontSize: "0.75rem"}}>{propsInt.children}</Typography>
        );

    }

    const match = useRouteMatch("/kontakt");

    let contactGreeting;

    if(!match) {

        contactGreeting = (
            <div className={styles["questions-container"]}>

                <div style={{position: "absolute", left: "1rem", top: "1rem"}}>
                    <Holland/>
                </div>

                <Typography variant="h3">
                    Skontaktuj się z nami
                </Typography>
                <Typography variant="body1" style={{maxWidth: "600px", textAlign: "justify"}}>
                Jeśli nie znaleźli Państwo informacji, której szukają zapraszamy do kontaktu.
                </Typography>
                <Button style={{color: "black"}} color="inherit" aria-label="menu" variant="contained" href="/kontakt" >
                    Skontaktuj się
                </Button>

                <div style={{position: "absolute", right: "1rem", top: "1rem"}}>
                    <Hidden mdDown>
                        <GermanyMap/>
                    </Hidden>
                </div>
                    
            </div>
        );

    }
    
    return (
        <div className={styles["main-container"]}>

            {contactGreeting}

            <div className={styles["footer-container"]}>
                <Container style={{padding: "2.5rem 2rem", borderBottom: "1px solid white"}}>
                    <Grid container justifyContent="space-around">

                        <Grid item xs={12} sm={5} md={3}>
                            <Logo
                                height="48"
                                color="white"
                            />
                        </Grid>

                        <Grid item xs={12} sm={5} md={3}>
                            <FooterLink to="/regulamin">Regulamin</FooterLink>
                            <FooterLink to="/polityka_prywatnosci">Polityka prywatności</FooterLink>
                            <FooterLink to="/polityka_ciasteczek">Polityka ciasteczek</FooterLink>
                            <FooterLink to="/RODO">RODO</FooterLink>
                            <FooterLink to="/reklamacja">Formularz reklamacji</FooterLink>
                        </Grid>

                        <Grid item xs={12} sm={5} md={3}>
                            <FooterLink to="/regulamin">O nas</FooterLink>
                            <FooterLink to="/polityka_prywatnosci">Trasy / Cennik</FooterLink>
                            <FooterLink to="/polityka_ciasteczek">Przed podróżą</FooterLink>
                            <FooterLink to="/RODO">Aktualności</FooterLink>
                            <FooterLink to="/reklamacja">Kontakt</FooterLink>
                        </Grid>
                        <Grid item xs={12} sm={5} md={3} style={{lineHeight: "0.75rem"}}>
                            <FooterText><p>Konta bankowe</p></FooterText>
                            <div style={{display: "flex", flexDirection: "row", gap: "1rem"}}>
                                <div>
                                    {oMoneyIcon}
                                </div>
                                <div>
                                    <FooterText>
                                        Numer konta PLN:<br/>
                                        52 1020 3668 0000 5902 0619 3595
                                        <br></br><br></br>
                                        Numer konta EUR:<br/>
                                        60 1020 3668 0000 5302 0594 1739
                                    </FooterText>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container style={{padding: '1rem 0 0 0'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography variant="body2">Copyright © Transport Osobowy Norbert Lisoń</Typography>
                        <Typography variant="body2">UX & Webdesign Webidea.pl</Typography>
                    </div>
                </Container>
            </div>
        </div>
    );

}