// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2IpGzJK00Q4hBnOtPPUDZA\\=\\={padding:1.5em;height:100%;color:#fff}.xFkADYe9b2WKYQrZGXRkhA\\=\\={display:flex;flex-direction:column;align-items:flex-start}._2FDd0yy9miSCTRUv0z0yIA\\=\\={padding-top:.5rem}.Maw9mMHxNGixxoUJY2a-hw\\=\\={padding-top:.25rem}.KW7u3G-CFCAhUdgtdbOquQ\\=\\={border-style:solid;border-width:1px;border-color:#fff;padding:.75rem}", "",{"version":3,"sources":["webpack://./src/js/components/Teaser/Teaser.module.scss"],"names":[],"mappings":"AAEA,6BACI,aAAA,CACA,WAAA,CACA,UAAA,CAGJ,4BACI,YAAA,CACA,qBAAA,CACA,sBAAA,CAGJ,6BACI,iBAAA,CAGJ,4BACI,kBAAA,CAGJ,4BACI,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.main-container {\n    padding: 1.5em;\n    height: 100%;\n    color: white;\n}\n\n.content-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.title-container {\n    padding-top: 0.5rem;\n}\n\n.subtitle-container {\n    padding-top: 0.25rem;\n}\n\n.icon-container {\n    border-style: solid;\n    border-width: 1px;\n    border-color: white;\n    padding: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "_2IpGzJK00Q4hBnOtPPUDZA==",
	"content-container": "xFkADYe9b2WKYQrZGXRkhA==",
	"title-container": "_2FDd0yy9miSCTRUv0z0yIA==",
	"subtitle-container": "Maw9mMHxNGixxoUJY2a-hw==",
	"icon-container": "KW7u3G-CFCAhUdgtdbOquQ=="
};
export default ___CSS_LOADER_EXPORT___;
