import React from "react";

class Control extends React.Component {

    constructor(props) {

        //visible
        //busy
        //display
        super(props);

        this.state = {
        };
    
    }

    render() {

        let children = this.props.children;

        if(this.props.visible) {

            children = this.props.children;
        
        }

        const style = this.props.style || {};

        if(!this.props.visible) {

            style.display = "none";

        }

        return (
            <div style={style} className={this.props.className}>
                {children}
            </div>
        );

    }

}

export default Control;