import React from "react";
import { AppBar } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    // style rule
    appBar: props => ({
        backgroundColor: 'white',
        boxShadow: props.boxShadow
    })
});
  
function CustomAppBar(props) {

    const { children } = props;

    // Simulated props for the purpose of the example
    let propsInner = {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    };
    // Pass the props as the first argument of useStyles()
    const classes = useStyles(propsInner);
  
    return <AppBar className={classes.appBar} > {children} </AppBar>;
    

}

class CustomAppBarContainer extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <CustomAppBar {...this.props}>
                {this.props.children}
            </CustomAppBar>
        );

    }

}

export { CustomAppBarContainer };