import React from "react";

import styles from "./NavigationMenu.module.scss";

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { Link } from "react-router-dom";


class NavigationMenu extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };
    
    }

    render() {

        return (
            <div className={styles["main-container"]}>
                <List component="nav">
                    <Link to="/">
                        <ListItem button>
                            <ListItemText primary="Strona główna" />
                        </ListItem>
                    </Link>
                    <Link to="/trasyicennik">
                        <ListItem button>
                            <ListItemText primary="Trasy / Cennik" />
                        </ListItem>
                    </Link>
                    <Link to="/onas">
                        <ListItem button>
                            <ListItemText primary="O nas" />
                        </ListItem>
                    </Link>
                    <Link to="/przedpodroza">
                        <ListItem button>
                            <ListItemText primary="Przed podróżą" />
                        </ListItem>
                    </Link>
                    <Link to="/aktualnosci">
                        <ListItem button>
                            <ListItemText primary="Aktualności" />
                        </ListItem>
                    </Link>
                    <Link to="/kontakt">
                        <ListItem button>
                            <ListItemText primary="Kontakt" />
                        </ListItem>
                    </Link>
                </List>
            </div>
        );
    
    }

}

export default NavigationMenu;